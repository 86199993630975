<script setup>
import { Head,  Link } from '@inertiajs/inertia-vue3';
import {ref, onMounted, reactive} from "vue";
import FlashMessageTailwind from "../Components/FlashMessageTailwind.vue";



const props = defineProps({
  brandInfo: {
    type: Object,
    required: true,
    default: null
  },

})

const mobileNavOpen = ref(false)
const mobileNav = ref(null)
const hamburger = ref(null)

// close on click outside
const clickHandler = ({ target }) => {
    if (!mobileNavOpen.value || mobileNav.value.contains(target) || hamburger.value.contains(target)) return
    mobileNavOpen.value = false
}

// close if the esc key is pressed
const keyHandler = ({ keyCode }) => {
    if (!mobileNavOpen.value || keyCode !== 27) return
    mobileNavOpen.value = false
}

onMounted(() => {
    document.addEventListener('click', clickHandler)
    document.addEventListener('keydown', keyHandler)
})

</script>

<template>
  <header class=" w-full z-30 bg-white min-h-[125px]" :class="{ 'dark': mode !== 'light' } " >
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <div class="flex items-center justify-between ">

        <!-- Site branding -->
        <div class="shrink-0 mr-4">
          <!-- Logo -->
            <a :href="brandInfo.url" class="font-medium   px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out text-white"><img :src="brandInfo.header.image" style="width: 125px"></a>

        </div>
        <!-- Desktop navigation -->
        <nav class="hidden md:flex md:grow">
          <!-- Desktop menu links -->

          <ul class="flex grow justify-start flex-wrap items-center">
            <li v-for="link in brandInfo.header.navigation " :key="link.title"><a :href="link.url" class="font-medium text-slate-800 hover:text-emerald-500   px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">
              {{link.title}}</a>
            </li>
          </ul>
        </nav>

        <!-- Mobile menu -->
        <div class="flex md:hidden">

          <!-- Hamburger button -->
          <button class="hamburger" ref="hamburger" :class="{ active: mobileNavOpen }" aria-controls="mobile-nav" :aria-expanded="mobileNavOpen" @click="mobileNavOpen = !mobileNavOpen">
            <span class="sr-only">Menu</span>
            <svg class="w-6 h-6 fill-current text-slate-900 dark:text-slate-100" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <rect y="4" width="24" height="2" />
              <rect y="11" width="24" height="2" />
              <rect y="18" width="24" height="2" />
            </svg>
          </button>

          <!-- Mobile navigation -->
          <transition
            enter-active-class="transition ease-out duration-200 transform"
            enter-from-class="opacity-0 -translate-y-2"
            enter-to-class="opacity-100 translate-y-0"
            leave-active-class="transition ease-out duration-200"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <nav
              id="mobile-nav"
              ref="mobileNav"
              v-show="mobileNavOpen"
              class="absolute top-full h-screen pb-16 z-20 left-0 w-full overflow-scroll no-scrollbar bg-white"
            >
              <ul class="px-5 py-2">

<!--                <li>-->
<!--                  <Navlink :href="route('home')" class="flex font-medium w-full text-slate-800 hover:text-blue-600 py-2">Home</Navlink>-->
<!--                </li>-->
<!--                <li>-->
<!--                  <Navlink :href="route('about')" class="flex font-medium w-full text-slate-800 hover:text-blue-600 py-2">About</Navlink>-->
<!--                </li>-->
<!--                <li>-->
<!--                  <Navlink :href="route('document.search')" class="flex font-medium w-full text-slate-800 hover:text-blue-600 py-2">Document Search</Navlink>-->
<!--                </li>-->
<!--<li><hr></li>-->
<!--                <li>-->
<!--                  <Navlink :href="route('login')" class="flex font-medium w-full text-slate-800 hover:text-blue-600 py-2">Sign in</Navlink>-->
<!--                </li>-->
<!--                <Navlink :href="route('register')" class="flex font-medium w-full text-slate-800 hover:text-blue-600 py-2">Register Account</Navlink>-->
              </ul>
            </nav>
          </transition>

        </div>

      </div>

    </div>
    <FlashMessageTailwind
        :msgSuccess="$page.props.flash.success "
        :msgError="$page.props.flash.error"
        :msgMessage="$page.props.flash.message"
    ></FlashMessageTailwind>
  </header>
</template>
